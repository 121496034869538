.heading-text{
  height: 50px;
  color:white;
   width: 50px; 
   border-radius: 5px;
}
.heading-icons{
  color: white; 
   height: 30px;
    width: 80px;
}
.create-call-div{
  margin-right: 50px;
}
.hangup{
  margin-right: 50px;
}
.join-call{
  font-size:20px;
}
@import url('https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap');

body {
  font-family: "ABeeZee", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('../src//Assets/images/background.jpg');
  color: white;
}
.video-section{
  /* background-image: url('./Assets/images/front.jpg'); */
  background-image: url('../src/Assets/images/front.jpg');
 border: 2px solid  #8a7f7f;
 border-radius: 5px;
  
}
.section-border{
  border: 2px solid rgb(134, 128, 128);
  border-radius: 5px;
}
/* .video-guest{
  color: red;
  background-color: rgb(247, 242, 242);
  padding: 10px;
  margin: 10px !important;
  margin-top: 30px !important;
  border-radius: 5px;
 
} */
/* .video-you{
  color: red;
  background-color: rgb(241, 233, 233);
  padding: 10px;
  margin: 10px !important;
  margin-top: 10px !important;
  border-radius: 5px;

} */


.input{
  height: 36px;
 
}
.sidebar {
  height: 700px;
  width: 70px;
  position: fixed;
  background-color:red;
  overflow-x: hidden;
  margin-top: 80px; 
  border-radius: 50px;
  margin: 15px;
}

.sidebar a {
  text-decoration: none;
  font-size: 20px;
  color: white;
  display: block;
}

.sidebar a:hover {
  color: #422a2a;
}
.font-text-join-call{
  font-size: 16px;
  margin-top: 10px;
}
@media (min-width:1025px) and (max-width:1440px){
  
  .webcamVideo{
    width: 443px;
  }
  .remoteVideo{
    
    width: 443px;
  }
  .sidebar {
    height: 430px !important;
    width: 65px !important;
    background-color:red;
    overflow-x: hidden;
    margin-top: 80px; 
    border-radius: 50px;
  }
  .sidebar a {
    text-decoration: none;
    font-size: 20px  !important;
    margin-top: 25px !important;
    color: white;
    display: block;
  }
 
}
@media (min-width:769px) and (max-width:1024px){
  .sidebar {
    height: 331px !important;
    width: 50px;
    height: 380px;
  }
  .sidebar a {
    font-size: 16px !important;
    padding: 15px;
  }
  .heading-video h6{
    font-size: 12px;
  }
  .webcamVideo{
    width: 315px;
  }
  .remoteVideo{
    
    width: 315px;
  }
}
@media (min-device-width:555px) and (max-device-width:768px){
  .sidebar {
    height: 280px;
    width: 35px;
  }
  .sidebar a{
    font-size: 15px !important;
    margin-left: 8px;
    padding: 3px;
    margin-top: 16px;
  }
  
  .heading-video h6{
    font-size: 8px;
  }
  .webcamVideo{
    width: 236px;
  }
  .remoteVideo{
    
    width: 236px;
  }
  .create-call{
    font-size: 12px;
    height: 40px;
  }
  .webcam{
    font-size: 11px;
    height: 40px;
    width: 120px;
  }
  .webcam i{
    font-size: 15px !important;
  }
}

@media (min-device-width:376px) and (max-device-width:425px){
  .webcam{
    text-decoration: none;
    font-size: 12px;
  }
  .heading-video{
    font-size: 12px;
  }
  .webcamVideo{
    width: 130px;
  }
  .remoteVideo{
    
    width: 130px;
  }
  .sidebar {
    display:flex ;
    width: 78%;
    height: 32px;
    margin-top: 293px !important;
    margin-left: 30px;
    
  }
  .input{
    width: 120px;
  }
  .sidebar a{
    font-size: 15px;
    margin-left: 29px;
    padding: 5px;
  }
}
@media (min-device-width:321px) and (max-device-width:375px){
  .webcam{
    text-decoration: none;
    font-size: 12px;
  }
  .heading-video{
    font-size: 12px;
  }
  .webcamVideo{
    width: 112px;
  }
  .remoteVideo{
    
    width: 112px;
  }
 
  .input{
    width: 70px;
  }
  .sidebar {
    display: flex;
    width: 79%;
    height: 32px;
    margin-top: 306px !important;
    margin-left: 21px;
  }
  .sidebar a{
    font-size: 15px !important;
    
    margin-left: 23px;
    padding: 5px;
  }
}
@media (min-device-width:300px) and (max-device-width:320px){
  .webcam{
    text-decoration: none;
    font-size: 12px;
  }
  .heading-video{
    font-size: 12px;
  }
  .webcamVideo{
    width: 100px;
  }
  .remoteVideo{
    
    width: 85px !important;
  }
  .input{
    width: 150px;
  }
   .sidebar {
    display: flex;
    width: 74%;
    height: 35px;
    margin-top: 312px !important;
    margin-left: 21px;
    
    
  }
  .sidebar i{
    font-size: 12px;
    padding: 14px;
  }
   
}
@media (min-device-width:1025px) and (max-device-width:1440px){
  
  .sidebar {
  padding: 20px;
  height: 300px;
    
  }
  .sidebar a {
    padding: 5px;
    margin-right: 20px !important;
  }
 
}

 video {
  width: 90%;
  height: 23vw;
  margin: 2rem;
  background: #2c3e50;
} 

 .videos {
  display: flex;
  align-items: center;
  justify-content: center;
} 